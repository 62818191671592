import React from "react";
import theme from "theme";
import { Theme, Text, Strong, List, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"cookie-policy"} />
		<Helmet>
			<title>
				Cookie Policy
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar />
		<Section>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-gap="16px"
				color="--primary"
			>
				<Text margin="0px 0px 0px 0px" font="--headline1" as="h1">
					Cookie Policy for IQ Researches
				</Text>
				<Text margin="0px 0px 0px 0px" font="--lead">
					Last updated: 22 July 2024{"\n\n"}
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					At IQ Researches, accessible from https://iqresearches.com, one of our main priorities is the privacy of our visitors. This Cookie Policy explains what cookies are, how we use them, the types of cookies we utilise, i.e., the information we collect using cookies, how that information is used, and how to control the cookie preferences. For further information on how we use, store, and keep your personal data secure, see our Privacy Policy.
				</Text>
				<Text margin="0px 0px 0px 0px" font="--headline2" as="h2">
					What are Cookies?{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.
				</Text>
				<Text margin="0px 0px 0px 0px" font="--headline2" as="h2">
					How Do We Use Cookies?{"\n"}
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base">
					The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.
				</Text>
				<Text margin="0px 0px 0px 0px" font="--headline2" as="h2">
					What Types of Cookies Do We Use?
				</Text>
				<Text margin="0px 0px 0px 0px" font="--headline3" as="h3">
					Necessary Cookies
				</Text>
				<List margin="0px 0px 0px 0px" padding="0px 0px 0px 20px" list-style-type="disc" as="ul">
					<Text margin="0px 0px 0px 0px">
						<Strong>
							CookieConsent:
						</Strong>
						{" "}Stores the user's cookie consent state for the current domain. Expires in 1 year.
					</Text>
				</List>
				<Text margin="0px 0px 0px 0px" font="--headline3" as="h3">
					Preferences Cookies
				</Text>
				<List margin="0px 0px 0px 0px" padding="0px 0px 0px 20px" list-style-type="disc" as="ul">
					<Text margin="0px 0px 0px 0px">
						<Strong>
							@@scroll#:
						</Strong>
						{" "}Unclassified. Preserves users states across page requests. Expires after the session.
					</Text>
				</List>
				<Text margin="0px 0px 0px 0px" font="--headline3" as="h3">
					Statistics Cookies
				</Text>
				<List margin="0px 0px 0px 0px" padding="0px 0px 0px 20px" list-style-type="disc" as="ul">
					<Text margin="0px 0px 0px 0px">
						<Strong>
							td:
						</Strong>
						{" "}Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator. Expires after the session.
					</Text>
				</List>
				<Text margin="0px 0px 0px 0px" font="--headline3" as="h3">
					Marketing Cookies
				</Text>
				<List margin="0px 0px 0px 0px" padding="0px 0px 0px 20px" list-style-type="disc" as="ul">
					<Text margin="0px 0px 0px 0px">
						<Strong>
							_ga:
						</Strong>
						{" "}Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels. Expires in 2 years.
					</Text>
				</List>
				<List margin="0px 0px 0px 0px" padding="0px 0px 0px 20px" list-style-type="disc" as="ul">
					<Text margin="0px 0px 0px 0px">
						<Strong>
							ga#:{" "}
						</Strong>
						Used to send data to Google Analytics about the visitor's device and behavior. Expires in 2 years.
					</Text>
				</List>
				<Text margin="0px 0px 0px 0px" font="--headline2" as="h2">
					What Types of Cookies Do We Use?
				</Text>
				<Text margin="0px 0px 0px 0px">
					You can manage your cookies preferences by clicking on the "Settings" button and enabling or disabling the cookie categories on the popup according to your preferences.{" "}
				</Text>
				<Text margin="0px 0px 0px 0px">
					Should you decide to change your preferences later through your browsing session, you can click on the “Privacy & Cookie Policy” tab on your screen. This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely.
				</Text>
				<Text margin="0px 0px 0px 0px">
					In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. To find out more on how to manage and delete cookies, visit wikipedia.org, www.allaboutcookies.org.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://iqresearches.com/cookie-policy/"} rel={"canonical"} place={"endOfHead"} rawKey={"661e64af6f473cbb7fd83651"} />
		</RawHtml>
	</Theme>;
});